<template>
  <div>
    <ModalEditYieldStatus
      v-if="showEditYieldStatus"
      :showModal="showEditYieldStatus"
      :yieldSettings="selectedYieldSetting"
      :onClickCancel="hideModal"
      @yieldSettingStatusChanged="yieldSettingStatusChanged"
    />

    <ModalCopyYieldSettings
      v-if="showCopyYieldSettings"
      :showModal="showCopyYieldSettings"
      :yieldSetting="selectedYieldSetting"
      :onClickCancel="hideModal"
      @yieldSettingCreated="yieldSettingCreated"
    />

    <ModalDeleteYieldSetting
      v-if="showDeleteYieldSetting"
      :showModal="showDeleteYieldSetting"
      :yieldSetting="selectedYieldSetting"
      :onClickCancel="hideModal"
      @yieldSettingDeleted="yieldSettingDeleted"
    />

    <table
      v-if="yieldSettings.length > 0"
      class="table is-fullwidth is-striped"
    >
      <thead>
        <tr>
          <th v-if="showMeetingtypeColumn" class="has-background-white-bis">
            Meeting type
          </th>
          <th v-if="showChannelColumn" class="has-background-white-bis">
            Channel
          </th>
          <th v-if="showSpaceColumn" class="has-background-white-bis">Space</th>
          <th v-if="showVoucherColumn" class="has-background-white-bis">
            Voucher
          </th>

          <th class="has-text-centered has-background-white-ter">Location</th>
          <th class="has-text-centered has-background-white-ter">Space</th>
          <th class="has-text-centered has-background-white-ter">Day</th>
          <th class="has-text-centered has-background-white-ter">Group</th>
          <th v-if="showValidFromColumn" class="has-background-grey-lighter">
            Valid from
          </th>
          <th class="has-text-right has-background-grey-lighter">Status</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(ys, index) in yieldSettings" :key="index">
          <td v-if="showMeetingtypeColumn">
            <a @click="goToYieldSettings(ys)">{{
              ys.MeetingtypeId | getMeetingtypeName
            }}</a>
          </td>
          <td v-if="showChannelColumn">{{ ys.ChannelName }}</td>
          <td v-if="showSpaceColumn">
            <span v-if="ys.SpaceId === 0">All spaces</span>
            <span v-if="ys.SpaceId > 0">{{ ys.SpaceName }}</span>
          </td>
          <td v-if="showVoucherColumn">{{ ys.VoucherName }}</td>
          <td class="has-text-centered">
            <font-awesome-icon
              v-if="ys.ApplyLocationUtilization"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-if="!ys.ApplyLocationUtilization"
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </td>
          <td class="has-text-centered">
            <font-awesome-icon
              v-if="ys.ApplySpaceUtilization"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-if="!ys.ApplySpaceUtilization"
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </td>
          <td class="has-text-centered">
            <font-awesome-icon
              v-if="ys.ApplyDayPercentage"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-if="!ys.ApplyDayPercentage"
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </td>
          <td class="has-text-centered">
            <font-awesome-icon
              v-if="ys.ApplyGroupSize"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-if="!ys.ApplyGroupSize"
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </td>
          <td v-if="showValidFromColumn">
            <span v-if="ys.VoucherId === 0">{{
              ys.ValidFrom | dateObjectIsoDateString
            }}</span>
          </td>
          <td class="has-text-right">
            <a @click="setShowEditYieldStatus(ys)">
              <span v-if="ys.Status === 'Approved'" class="tag is-success">{{
                ys.Status
              }}</span>
              <span v-if="ys.Status === 'Draft'" class="tag is-warning">{{
                ys.Status
              }}</span>
              <span v-if="ys.Status === 'Pending'" class="tag is-light">{{
                ys.Status
              }}</span>
              <span v-if="ys.Status === 'Denied'" class="tag is-danger">{{
                ys.Status
              }}</span>
            </a>
          </td>
          <td>
            <a>
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'info-circle']" />
              </span>
            </a>
            <a @click="goToYieldSettings(ys)">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'edit']" />
              </span>
            </a>
            <a @click="setShowCopyYieldSettings(ys)">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'copy']" />
              </span>
            </a>
            <a @click="setShowDeleteYieldSetting(ys)">
              <span class="icon has-text-danger">
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    ModalEditYieldStatus: () =>
      import('@/components/Yield/ModalEditYieldStatus'),
    ModalCopyYieldSettings: () =>
      import('@/components/Yield/ModalCopyYieldSettings'),
    ModalDeleteYieldSetting: () =>
      import('@/components/Yield/ModalDeleteYieldSetting'),
  },

  props: {
    showMeetingtypeColumn: {
      type: Boolean,
      default: true,
    },

    showChannelColumn: {
      type: Boolean,
      default: true,
    },

    showSpaceColumn: {
      type: Boolean,
      default: true,
    },

    showVoucherColumn: {
      type: Boolean,
      default: true,
    },

    showValidFromColumn: {
      type: Boolean,
      default: true,
    },

    yieldSettings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showCopyYieldSettings: false,
      showDeleteYieldSetting: false,
      showEditYieldStatus: false,
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsList']),
  },

  created() {},

  methods: {
    ...mapMutations('yieldStore', [
      'setYieldSettingsState',
      'setYieldSettingsList',
    ]),

    goToYieldSettings(yieldSettings) {
      this.setYieldSettingsState({
        isSaving: false,
        isSavingSuccess: false,
        isSavingError: false,
        yieldSettings: yieldSettings,
      })

      this.$router.push({
        name: `settings-yield-detail`,
        params: { yieldId: yieldSettings.Id },
      })
    },

    yieldSettingCreated(yieldSettings) {
      this.yieldSettingsList.yieldSettings.push(yieldSettings)
      this.goToYieldSettings(yieldSettings)
    },

    yieldSettingDeleted(yieldSettingId) {
      let self = this

      let index = self.yieldSettings.findIndex((ys) => ys.Id === yieldSettingId)
      if (index > -1) {
        self.yieldSettings.splice(index, 1)
      }
    },

    yieldSettingStatusChanged(yieldSettings) {
      let self = this

      let index = self.yieldSettings.findIndex(
        (ys) => ys.Id === yieldSettings.Id
      )
      if (index > -1) {
        self.yieldSettings[index].Status = yieldSettings.Status
      }
    },

    setShowCopyYieldSettings(yieldSetting) {
      this.selectedYieldSetting = yieldSetting
      this.showCopyYieldSettings = true
    },

    setShowDeleteYieldSetting(yieldSetting) {
      this.selectedYieldSetting = yieldSetting
      this.showDeleteYieldSetting = true
    },

    setShowEditYieldStatus(yieldSetting) {
      this.selectedYieldSetting = yieldSetting
      this.showEditYieldStatus = true
    },

    hideModal() {
      this.selectedChannelId = 0
      this.selectedYieldSetting = null
      this.showCopyYieldSettings = false
      this.showDeleteYieldSetting = false
      this.showEditYieldStatus = false
    },
  },
}
</script>

<style></style>
